import React from 'react';

import { Grid, Typography, Table, TableBody, TableCell, TableContainer, TableHead, 
    TableRow, Paper } from '@material-ui/core';
import { Skeleton } from '@material-ui/lab';

import { formatNumber, formatSnowDepth, SourceUnit } from '../../utils/DataDisplay';
import LastUpdatedChip from '../common/LastUpdatedChip';


export default function SkiAreaSnowReport(props) {
    const { selectedLocation, unitPreference } = props;

    const getFormattedElevation = (elevation) => {
        if (elevation > 0) {
            return formatNumber(elevation, SourceUnit.FEET, props.unitPreference, true);
        }
    };

    const snowDepthCell = (
        <TableCell> 
            {selectedLocation
                ? <Grid container justifyContent="flex-start" alignItems="center" spacing={2}>
                    <Grid item>
                        <Typography variant="body2">
                            {formatSnowDepth(selectedLocation.snowDepth, unitPreference)}
                        </Typography>
                    </Grid>
                    <Grid item>
                        <LastUpdatedChip date={selectedLocation.lastUpdated}/>
                    </Grid>
                </Grid>
                : <Skeleton/>
            }
        </TableCell>
    );

    return (
        <TableContainer component={Paper}>
            <Table aria-label="snow-report-table">
                <TableHead>
                    <TableRow>
                        <TableCell>Top Elevation</TableCell>
                        <TableCell>Vertical Drop</TableCell>
                        <TableCell>Estimated Base Depth</TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    <TableRow>
                        <TableCell>
                            {getFormattedElevation(selectedLocation.topElevation)}
                        </TableCell>
                        <TableCell>
                            {getFormattedElevation(selectedLocation.verticalDrop)}
                        </TableCell>
                        {snowDepthCell}
                    </TableRow>
                </TableBody>
            </Table>
        </TableContainer>
    );
}
