import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';

import 'leaflet/dist/leaflet.css';
import 'react-leaflet-markercluster/dist/styles.min.css';
import L from 'leaflet';
import { Map, Marker, Popup, TileLayer } from 'react-leaflet';
import MarkerClusterGroup from 'react-leaflet-markercluster';
import MarkerPrimary from '../../images/map-marker-primary.png';
import MarkerSecondary from '../../images/map-marker-secondary.png';
import MarkerPrimaryStar from '../../images/map-marker-primary-star.png';
import MarkerSecondaryStar from '../../images/map-marker-secondary-star.png';

import './LeafletCluster.css';

import { withStyles } from '@material-ui/core/styles';

const useStyles = (theme) => ({
    map: {
        zIndex: 0,
        height: 500,
    },
    allStationMap: {
        zIndex: 0,
        marginTop: theme.spacing(1)
    },
    popup: {
        '& .leaflet-popup-content-wrapper': {
            ...theme.typography.caption,
            backgroundColor: theme.palette.background.paper,
            color: theme.palette.text.primary
        },
        '& .leaflet-popup-tip': {
            background: theme.palette.background.paper,
        }
    }
});

const tileLayer = (prefersDarkMode) => {
    const tileURL = prefersDarkMode
        ? "https://tiles.stadiamaps.com/tiles/alidade_smooth_dark/{z}/{x}/{y}{r}.png"
        : "https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png";

    return (<TileLayer url={tileURL} />);
}

const icon = (star, prefersDarkMode) => {
    let iconURL = MarkerSecondary;
    if (star && prefersDarkMode) {
        iconURL = MarkerSecondaryStar;
    } else if (star && !prefersDarkMode) {
        iconURL = MarkerPrimaryStar;
    } else if (!star && !prefersDarkMode) {
        iconURL = MarkerPrimary;
    }

    return (
        new L.icon({
            iconUrl: iconURL,
            iconAnchor: [18, 34],
            popupAnchor: [0, -32],
            shadowUrl: null
        })
    );
}

const mapProps = {
    attributionControl: false,
    dragging: !L.Browser.mobile,
    tap: !L.Browser.mobile
}

class LocationMap extends Component {
    createCluster = cluster => {
        return L.divIcon({
            html: `<span>${cluster.getChildCount()}</span>`,
            className: `marker-cluster ${this.props.prefersDarkMode ? 'dark' : 'light'}`,
            iconSize: L.point(40, 40, true),
        });
    }

    onMarkerClick = (urlName) => {
        if (urlName) {
            this.props.history.push(`/location/${urlName}`);
        }
    }

    marker = (location, prefersDarkMode) => {
        const { selectedLocationURLName, classes } = this.props;
        const { lat, lng } = location.coordinates;
        const { name, urlName } = location;

        let star = false;
        if (selectedLocationURLName && selectedLocationURLName === urlName) {
            star = true;
        }

        return (
            <Marker
                icon={icon(star, prefersDarkMode)} position={[lat, lng]} key={urlName}
                onMouseOver={(e) => e.target.openPopup()} onMouseOut={(e) => e.target.closePopup()}
                onClick={() => this.onMarkerClick(urlName)}
            >
                <Popup
                    closeButton={false} className={classes.popup}
                    key={`${urlName}-popup-${this.props.prefersDarkMode ? 'dark' : 'light'}`}
                >
                    {name}
                </Popup>
            </Marker>
        );
    }

    boundsOrCenter = () => {
        if (this.props.locations.length > 1) {
            return { bounds: this.props.bounds };
        } else if (this.props.locations.length === 1) {
            const location = this.props.locations[0]
            return { center: [location.coordinates.lat, location.coordinates.lng], zoom: 8 };
        } else {
            return { center: [42, -115], zoom: 8 };
        }
    }

    render() {
        const { maxClusterRadius, prefersDarkMode, classes } = this.props;

        return (
            <Map
                {...mapProps} {...this.boundsOrCenter()}
                className={`${classes.map} ${classes.allStationMap}`}
            >
                {tileLayer(this.props.prefersDarkMode)}
                <MarkerClusterGroup
                    iconCreateFunction={this.createCluster}
                    showCoverageOnHover={false} maxClusterRadius={maxClusterRadius}
                >
                    {this.props.locations.map((location) => (
                        this.marker(location, prefersDarkMode)
                    ))}
                </MarkerClusterGroup>
            </Map>
        );
    }
}

export default withRouter(withStyles(useStyles)(LocationMap));
