import React from 'react';

import {Grid, Typography, Divider, Hidden, createStyles, makeStyles, Theme }
    from '@material-ui/core';
import { ErrorOutline } from '@material-ui/icons';

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {},
        fullPage: {
            marginTop: theme.spacing(24),
            [theme.breakpoints.down('xs')]: {
                marginTop: theme.spacing(8)
            }
        },
        wrapIcon: {
            verticalAlign: 'middle',
            display: 'inline-flex'
        }
    })
);

interface ErrorMessageProps {
    message: string;
    fullPage?: boolean
    justifyContentCenter?: boolean
}

export default function ErrorMessage(props: ErrorMessageProps) {
    const classes = useStyles(props);

    return (
        <Grid 
            container spacing={3} alignItems="center"
            justifyContent={props.justifyContentCenter ? 'center' : 'flex-start'}
            className={`${classes.root} ${props.fullPage ? classes.fullPage : ''}`}
        >
            <Grid item>
                <Typography className={classes.wrapIcon}>
                    <ErrorOutline/>
                </Typography>
            </Grid>
            <Hidden xsDown>
                <Divider flexItem={true} orientation="vertical"/>
            </Hidden>
            <Grid item>
                <Typography variant="body2" data-testid="error-message">
                    {props.message}
                </Typography>
            </Grid>
        </Grid>
    );
}
