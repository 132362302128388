import React, { useState, useEffect } from 'react';
import { useParams, Link as RouterLink, useHistory, useLocation } from 'react-router-dom';

import { Container, Grid, Typography, Link, makeStyles, Box, Tabs, Tab }
    from '@material-ui/core';
import { Skeleton, TabContext, TabPanel } from '@material-ui/lab';

import { sendServerRequest } from '../../api/restfulAPI';
import Forecast from './Forecast';
import Details from './Details';
import ErrorPage from '../margins/ErrorPage';
import Nearby from './Nearby';
import SnowReport from './SnowReport';

const LocationTab = Object.freeze({
    FORECAST: 'forecast',
    SNOW_REPORT: 'snow-report',
    NEARBY: 'nearby',
    DETAILS: 'details'
});

const useStyles = makeStyles((theme) => ({
    header: {
        marginTop: theme.spacing(3)
    },
    tabPanel: {
        padding: theme.spacing(2, 1, 2, 1)
    },
    errorMessage: {
        padding: theme.spacing(24, 0, 24, 0)
    }
}));

export default function LocationPage(props) {
    const { prefersDarkMode, unitPreference } = props;

    const [selectedLocation, setSelectedLocation] = useState(null);

    useEffect(() => {
        if (selectedLocation) {
            document.title = `${selectedLocation.name} | Snotel`;
        }
    }, [selectedLocation]);

    let urlParams = useParams();
    const [locationNotFound, setlocationNotFound] = useState(false);

    useEffect(() => {
        sendServerRequest(`locations/${urlParams.stationUrlName}/type`)
        .then((initialResponse => {
            if (initialResponse.statusCode >= 200 && initialResponse.statusCode <= 299) {
                setlocationNotFound(false);
                const locationTypeID = initialResponse.body.typeID;
                const requestType = locationTypeID === 1 ? 'stations' : 'ski-areas';
                const locationTypeProp = locationTypeID === 1 ? 'stations' : 'skiAreas';

                sendServerRequest(`${requestType}?urlName=${urlParams.stationUrlName}`)
                .then((response => {
                    if (response.statusCode >= 200 && response.statusCode <= 299) {
                        setSelectedLocation(response.body[locationTypeProp][0]);
                    } else {
                        console.error("Response code: ", response.statusCode, response.statusText);
                    }
                }));
            } else if (initialResponse.statusCode === 404) {
                setlocationNotFound(true);
            } else {
                console.error("Response code: ", initialResponse.statusCode, initialResponse.statusText);
            }
        }));
    }, [urlParams.stationUrlName]);

    const [selectedStationStateName, setSelectedStationStateName] = useState('')

    useEffect(() => {
        if (selectedLocation) {
            sendServerRequest(`states?stateCode=${selectedLocation.stateCode}`)
            .then((response => {
                if (response.statusCode >= 200 && response.statusCode <= 299) {
                    setSelectedStationStateName(response.body.states[0].stateName);
                } else {
                    console.error("Response code: ", response.statusCode, response.statusText);
                }
            }));
        }
    }, [selectedLocation]);

    const classes = useStyles();

    const stateAndCountryText = (
        <Grid container alignItems="center" spacing={1}>
            <Grid item>
                {selectedStationStateName ? <Link
                    color="secondary" underline="none" variant="body1"
                    data-testid="state-link" component={RouterLink}
                    to={selectedLocation ? `/explore/${selectedLocation.stateCode}` : '/'}

                >
                    {selectedStationStateName}
                </Link> : <Skeleton width="10ch" />}
            </Grid>
            <Grid item>
                <Typography variant="h6" component="span">
                    {' · '}
                </Typography>
            </Grid>
            <Grid item>
                <Typography>
                    United States
                </Typography>
            </Grid>
        </Grid>
    );

    const history = useHistory();
    const location = useLocation();

    const [activeTab, setActiveTab] = useState(LocationTab.FORECAST);

    useEffect(() => {
        if (location.hash === '') {
            history.replace(`${location.pathname}#${LocationTab.FORECAST}`);
        } else if (location.hash.substring(1) !== activeTab) {
            if (Object.values(LocationTab).some(key => key === location.hash.substring(1))) {
                setActiveTab(location.hash.substring(1));
            }
        }
    }, [activeTab, history, location]);

    const handleChange = (event, newActiveTab) => {
        history.replace(`${location.pathname}#${newActiveTab}`);
    };

    const forecastProps = () => {
        if (selectedLocation?.locationTypeID === 1) {
            return {
                elevation: selectedLocation.elevation
            };
        } else if (selectedLocation?.locationTypeID === 2) {
            return {
                bottomElevation: selectedLocation.bottomElevation,
                topElevation: selectedLocation.topElevation
            };
        }
    }

    const tabPanel = (
        <TabContext value={activeTab}>
            <Box position="static">
                <Tabs
                    value={activeTab} onChange={handleChange} variant="scrollable"
                    aria-label="location-page-tabs"
                >
                    {Object.values(LocationTab).map((tab) => (
                        <Tab
                            label={tab.replace('-', ' ')}
                            value={tab} key={tab} data-testid={`${tab}-tab`}
                        />
                    ))}
                </Tabs>
            </Box>
            <TabPanel value={LocationTab.FORECAST} className={classes.tabPanel}>
                <Forecast
                    forecastIsNotSupported={
                        selectedLocation != null
                        && !selectedLocation.hasOwnProperty('forecastZoneID')
                    }
                    zoneID={selectedLocation?.forecastZoneID} unitPreference={unitPreference}
                    {...forecastProps()}
                />
            </TabPanel>
            <TabPanel value={LocationTab.SNOW_REPORT} className={classes.tabPanel}>
                <SnowReport selectedLocation={selectedLocation} unitPreference={unitPreference} />
            </TabPanel>
            <TabPanel value={LocationTab.NEARBY} className={classes.tabPanel}>
                <Nearby
                    prefersDarkMode={prefersDarkMode}
                    selectedLocation={selectedLocation}
                />
            </TabPanel>
            <TabPanel value={LocationTab.DETAILS} className={classes.tabPanel}>
                <Details
                    selectedLocation={selectedLocation}
                />
            </TabPanel>
        </TabContext>
    );

    const base = (
        <Grid container spacing={1} className={classes.header}>
            <Grid item xs={12} sm={12} md={4}>
                <Typography variant="h4" component="h1">
                    {selectedLocation ? selectedLocation.name : <Skeleton />}
                </Typography>
                {stateAndCountryText}
            </Grid>
            <Grid item xs={12} sm={12} md={8}>
                {tabPanel}
            </Grid>
        </Grid>
    );

    const locationNotFoundMessage = (
        <ErrorPage
            title="Location Not Found | Snotel" message="This location could not be found."
        />
    )

    return (
        <Container maxWidth="lg">
            {locationNotFound ? locationNotFoundMessage : base}
        </Container>
    );
}
