import React from 'react';

import { Chip, createStyles, makeStyles, Theme } from '@material-ui/core';
import { getLastUpdatedText } from '../../utils/DateTime';

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        chip: {
            backgroundColor: theme.palette.type === 'light' 
                ? theme.palette.grey[400] : theme.palette.grey[700]
        }
    })
);

interface LastUpdatedChipProps {
    date: number;
}

export default function LastUpdatedChip(props: LastUpdatedChipProps) {
    const classes = useStyles();

    if (props.date) {
        return(
            <Chip size="small" label={getLastUpdatedText(props.date)} classes={{ root: classes.chip }}/>
        );
    }
}
