import React, { useState, useEffect, useRef } from 'react';
import { Link, useHistory } from 'react-router-dom';

import { AppBar, Toolbar, Grid, TextField, InputAdornment, Popper, Paper, 
    Fade, Container, useMediaQuery, Hidden, ClickAwayListener, MenuList,
    MenuItem, ListItemIcon, Box, ButtonBase, makeStyles } from '@material-ui/core';
import { Search, Place } from '@material-ui/icons';

import { sendServerRequest } from '../../api/restfulAPI';

import HomeButtonOrange from '../../images/HomeButtonOrange.png';
import HomeButtonBlue from '../../images/HomeButtonBlue.png';

const useStyles = makeStyles((theme) => ({
    homeButton: {
        borderRadius: theme.shape.borderRadius,
        [theme.breakpoints.down('xs')]: {
            marginTop: theme.spacing(1)
        }
    },
    img: {
        height: theme.spacing(4)
    },
    search: props => ({
        backgroundColor: props.prefersDarkMode ? null : theme.palette.primary.light,
        borderRadius: theme.shape.borderRadius
    }),
    mobileSearch: {
        width: '100%',
        margin: theme.spacing(1, 0, 1, 0)
    },
    desktopSearch: props => ({
        transition: theme.transitions.create('width'),
        width: props.searchFocus ? '40ch' : '30ch'
    }),
    contrastText: {
        color: theme.palette.primary.contrastText
    },
    outlinedInput: {
        '& .MuiOutlinedInput-root': {
            '&:hover fieldset': {
                borderColor: theme.palette.grey[600]
            },
            '&.Mui-focused fieldset': {
                borderColor: theme.palette.grey[600]
            }
        }
    },
    suggestions: {
        overflowY: 'auto',
        maxHeight: '50vh',
    },
    noResults: {
        padding: theme.spacing(2)
    }
}));

export default function Navigation(props) {
    const [anchorEl, setAnchorEl] = useState(null);

    const [searchTerm, setSearchTerm] = useState('');
    const [searchTermFirstLetter, setSearchTermFirstLetter] = useState('');
    const [locations, setLocations] = useState([]);
    const [filteredLocations, setFilteredLocations] = useState([]);

    const [searchFocus, setSearchFocus] = useState(false);
    const [autoFocusItem, setAutoFocusItem] = useState(false);

    const homeButtonRef = useRef(null);

    const handleInputChange = (event) => {
        setSearchTerm(event.target.value);

        if (event.target.value.length > 1) {
            setAnchorEl(event.currentTarget)
        } else {
            setAnchorEl(null);
        }
    }

    useEffect(() => {
        setSearchTermFirstLetter(searchTerm.charAt(0));

        setFilteredLocations(
            locations.filter(location => 
                location.name.toLowerCase().startsWith(searchTerm.toLowerCase())
            )
        );
    }, [searchTerm, locations]);

    //Load search suggestions into client after the first letter is typed
    useEffect(() => {
        if (searchTermFirstLetter !== '') {
            sendServerRequest(`locations/search?q=${searchTermFirstLetter}&getSuggestions=true`)
            .then((response => {
                if (response.statusCode >= 200 && response.statusCode <= 299) {
                    setLocations(response.body.locations);
                } else {
                    console.error("Response code: ", response.statusCode, response.statusText);
                }
            }));
        }
    }, [searchTermFirstLetter]);

    const closeSuggestions = (resetInput) => {
        if (resetInput) {
            setSearchTerm('');
        }
        setSearchFocus(false);
        setAnchorEl(null);
        setAutoFocusItem(false);
    }
    
    const handleListKeyDown = (event) => {
        if (event.key === 'Tab' && !event.shiftKey) {
            event.preventDefault();
            closeSuggestions(false);
        } else if (event.key === 'Tab' && event.shiftKey) {
            event.preventDefault();
            closeSuggestions(false);
            homeButtonRef.current.focus();
        }
    };

    const classes = useStyles(
        {prefersDarkMode: props.prefersDarkMode, searchFocus: searchFocus}
    );

    const suggestions = () => {
        return (
            <Paper className={classes.suggestions}>
                <MenuList 
                    data-testid="search-suggestions" onKeyDown={handleListKeyDown}
                    autoFocusItem={autoFocusItem}
                >
                    {filteredLocations.map((location, i) => (
                        <MenuItem 
                            component={Link} onClick={() => closeSuggestions(true)}
                            to={`/location/${location.urlName}`}
                            key={i.toString()}
                        >
                            <ListItemIcon>
                                <Place/>
                            </ListItemIcon>
                            {`${location.name} · ${location.stateCode}`}
                        </MenuItem>
                    ))}
                </MenuList>
            </Paper>
        );
    };

    const suggestionsDropdown = (
        <Popper 
            open={Boolean(anchorEl)} anchorEl={anchorEl} 
            role={undefined} placement="bottom-start" transition
        >
            {({ TransitionProps }) => (
                <Fade {...TransitionProps} timeout={200}>
                    {suggestions()}
                </Fade>
            )}
        </Popper>
    );

    const homeButton = (
        <ButtonBase 
            component={Link} to="/" ref={homeButtonRef} 
            focusRipple className={classes.homeButton}
        >
            <img 
                className={classes.img} alt="Snotel"
                src={props.prefersDarkMode ? HomeButtonBlue : HomeButtonOrange}
            />
        </ButtonBase>
    );

    const handleOnFocus = (event) => {
        if (searchTerm.length > 1) {
            setAnchorEl(event.currentTarget)
        }
        setSearchFocus(true);
    };

    const history = useHistory();
    const handleSearchKeyUp = (event) => {
        if (event.key === 'ArrowDown' && Boolean(anchorEl)) {
            event.preventDefault();
            setAutoFocusItem(true);
        } else if (event.key === 'Escape') {
            setSearchTerm('');
            if (Boolean(anchorEl)) {
                setAnchorEl(null);
            }
        } else if (event.key === 'Enter' && searchTerm.length > 0) {
            if (Boolean(anchorEl)) {
                setAnchorEl(null);
            }
            setSearchFocus(false);
            event.target.blur();

            if (filteredLocations.length === 1) {
                history.push(`/location/${filteredLocations[0].urlName}`)
            } else {
                history.push(`/search?q=${searchTerm}`)
            }
        }
    };

    const handleSearchKeyDown = (event) => {
        if (event.key === 'Tab' && !event.shiftKey) {
            if (Boolean(anchorEl)) {
                event.preventDefault();
                closeSuggestions(false);
            } else {
                setSearchFocus(false);
            }
        } else if (event.key === 'Tab' && event.shiftKey) {
            if (Boolean(anchorEl)) {
                closeSuggestions(false);
            } else {
                setSearchFocus(false);
            }
            event.preventDefault();
            homeButtonRef.current.focus();
        }
    }

    const smUp = useMediaQuery(theme => theme.breakpoints.up('sm'));

    const textField = (
        <TextField
            color={props.prefersDarkMode ? "secondary" : null} 
            placeholder="Search for a Mountain" variant="outlined"
            value={searchTerm} onChange={handleInputChange}
            className={
                `${classes.search} ${smUp 
                    ? classes.desktopSearch : classes.mobileSearch}`
            }
            onFocus={handleOnFocus} onKeyUp={handleSearchKeyUp}
            onKeyDown={handleSearchKeyDown}
            InputProps={{
                startAdornment: (
                    <InputAdornment position="start">
                        <Search/>
                    </InputAdornment>
                ),
                classes: {
                    adornedStart: classes.contrastText,
                    input: classes.contrastText
                }
            }}
            classes={{ //override mui classes in light theme
                root: props.prefersDarkMode ? null : classes.outlinedInput
            }}
            data-testid="search-input-box"
        />
    );

    const search = (
        process.env.REACT_APP_DOWN !== 'true' &&
            <ClickAwayListener onClickAway={() => closeSuggestions(false)}>
                <Box>
                    {textField}
                    {suggestionsDropdown}
                </Box>
            </ClickAwayListener>
    );

    const mobileMenu = (
        <>
            {homeButton}
            {search}
        </>
    );

    const desktopMenu = (
        <Toolbar disableGutters>
            <Grid container alignItems="center" justifyContent="flex-start" spacing={1}>
                <Grid item>
                    {homeButton}
                </Grid>
                <Grid item>
                    {search}
                </Grid>
            </Grid>
        </Toolbar>
    );

    return (
        <AppBar position="sticky" color={props.prefersDarkMode ? "inherit" : "primary"}>
            <Container maxWidth="lg">
                <Hidden xsDown>
                    {desktopMenu}
                </Hidden> 
                <Hidden smUp>
                    {mobileMenu}
                </Hidden>
            </Container>
        </AppBar>
    );
}
