import React from 'react';

import {
    Grid, Typography, Table, TableBody, TableCell, TableContainer, TableHead,
    TableRow, Paper, Link
} from '@material-ui/core';
import { Skeleton } from '@material-ui/lab';

import { formatNumber, formatSnowDepth, SourceUnit } from '../../utils/DataDisplay';
import LastUpdatedChip from '../common/LastUpdatedChip';

export default function StationSnowReport(props) {
    const { selectedLocation, unitPreference } = props;

    const snotelLink = (snotelID, stateCode, snotelNetwork) => {
        return (
            <Link
                title="Snotel station page - opens in a new tab"
                color="secondary" underline="none"
                href={`https://wcc.sc.egov.usda.gov/nwcc/site?sitenum=${snotelID}`}
                target="_blank" rel="noopener"
            >
                {`${snotelID}:${stateCode}:${snotelNetwork}`}
            </Link>
        );
    };

    const tableBody = (
        <TableBody>
            <TableRow>
                <TableCell>
                    {selectedLocation
                        ? formatNumber(
                            selectedLocation.elevation, SourceUnit.FEET, unitPreference, true
                        ) : <Skeleton />
                    }
                </TableCell>
                <TableCell>
                    {selectedLocation
                        ? <Grid
                            container justifyContent="flex-start" alignItems="center" spacing={2}
                        >
                            <Grid item>
                                <Typography variant="body2">
                                    {formatSnowDepth(selectedLocation.snowDepth, unitPreference)}
                                </Typography>
                            </Grid>
                            <Grid item>
                                <LastUpdatedChip date={selectedLocation.lastUpdated} />
                            </Grid>
                        </Grid>
                        : <Skeleton />
                    }
                </TableCell>
                <TableCell>
                    {selectedLocation
                        ? snotelLink(
                            selectedLocation.snotelID, selectedLocation.stateCode,
                            selectedLocation.snotelNetwork
                        ) : <Skeleton />
                    }
                </TableCell>
            </TableRow>
        </TableBody>
    );

    return (
        <TableContainer component={Paper}>
            <Table aria-label="snow-report-table">
                <TableHead>
                    <TableRow>
                        <TableCell>Altitude</TableCell>
                        <TableCell>Snow Depth</TableCell>
                        <TableCell>Source</TableCell>
                    </TableRow>
                </TableHead>
                {tableBody}
            </Table>
        </TableContainer>
    );
}
