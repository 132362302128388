import React, { useState } from 'react';

import { Box, Collapse, IconButton, makeStyles, TableCell, TableRow, Typography, useMediaQuery } from '@material-ui/core';
import { KeyboardArrowUp, KeyboardArrowDown } from '@material-ui/icons';

import {
    ClearDay, ClearNight, Cloudy, Fog, FreezingRain, PartlyCloudyDay, PartlyCloudyNight,
    Rain, Snow
} from '../common/WeatherIcons';
import { formatNumber, SourceUnit } from '../../utils/DataDisplay';

const useStyles = makeStyles((theme) => ({
    arrowButton: {
        marginRight: theme.spacing(0.5)
    },
    weatherStatusCell: {
        display: 'flex',
        alignItems: 'center',
        flexWrap: 'wrap'
    },
    weatherIcon: {
        marginRight: theme.spacing(1)
    },
    expandedRow: {
        padding: theme.spacing(1, 0, 1, 0)
    },
    collapseRow: {
        paddingBottom: 0,
        paddingTop: 0
    },
    tableCell: {
        padding: theme.spacing(0.75, 2.25, 0.75, 1.25)
    }
}));

export default function ForecastPeriodRow(props) {
    const getWeatherStatusText = (period) => {
        if (!period.hasOwnProperty('iconType')) {
            return null;
        }

        const { isDaytime, iconType } = period;

        if (isDaytime && (iconType === 'clear' || iconType === 'mostly_clear')) {
            return 'Sunny';
        } else if (isDaytime && iconType === 'partly_cloudy') {
            if (period.detailedForecast.toLowerCase().includes('mostly sunny')) {
                return 'Mostly Sunny';
            } else if (period.detailedForecast.toLowerCase().includes('partly sunny')) {
                return 'Partly Sunny';
            } else {
                return 'Partly Cloudy';
            }
        } else {
            return iconType.toLowerCase().split('_')
                .map((s) => s.charAt(0).toUpperCase() + s.substring(1)).join(' ');
        }
    };

    const classes = useStyles();

    const weatherIconProps = { classes: { root: classes.weatherIcon } }
    const getWeatherIcon = (period) => {
        if (!period.hasOwnProperty('iconType')) {
            return null;
        }

        const { isDaytime, iconType } = period;
        switch (iconType) {
            case 'cloudy':
                return <Cloudy {...weatherIconProps} />;
            case 'clear':
            case 'mostly_clear':
            case 'cold':
                if (isDaytime) {
                    return <ClearDay {...weatherIconProps} />;
                } else {
                    return <ClearNight {...weatherIconProps} />;
                }
            case 'fog':
                return <Fog {...weatherIconProps} />;
            case 'snow':
                return <Snow {...weatherIconProps} />;
            case 'rain':
                return <Rain {...weatherIconProps} />;
            case 'partly_cloudy':
                if (isDaytime) {
                    return <PartlyCloudyDay {...weatherIconProps} />;
                } else {
                    return <PartlyCloudyNight {...weatherIconProps} />;
                }
            case 'freezing_rain':
                return <FreezingRain {...weatherIconProps} />;
            default:
                console.error('Unsupported icon type: ', iconType)
        }
    };

    const smUp = useMediaQuery(theme => theme.breakpoints.up('sm'));

    const getWeatherStatus = (period) => {
        return (
            <Box className={classes.weatherStatusCell}>
                {getWeatherIcon(period)}{smUp ? getWeatherStatusText(period) : ''}
            </Box>
        );
    };

    const { unitPreference, period, detailedForecastEnabled } = props;

    const [open, setOpen] = useState(false);

    const arrowButton = (
        <IconButton
            aria-label="expand row" size="small" onClick={() => setOpen(open => !open)}
            className={classes.arrowButton}
            style={detailedForecastEnabled ? null : {visibility: 'hidden'}}
        >
            {open ? <KeyboardArrowUp /> : <KeyboardArrowDown />}
        </IconButton>
    );

    const formattedTemperature =
        formatNumber(period.temperature, SourceUnit.FAHRENHEIT, unitPreference);
    const formattedWind = formatNumber(period.windSpeed, SourceUnit.MPH, unitPreference);

    return (
        <>
            <TableRow data-testid={`period-${period.number}`}>
                <TableCell className={classes.tableCell}>
                    {smUp && arrowButton}
                    {period.isDaytime
                        && `${period.displayDayOfWeek} · ${period.displayDate}`
                    }
                </TableCell>
                <TableCell  className={classes.tableCell}>
                    {getWeatherStatus(period)}
                </TableCell>
                <TableCell className={classes.tableCell}>{formattedTemperature}</TableCell>
                {smUp &&
                    <TableCell>
                        {period.windDirection} {formattedWind}
                    </TableCell>
                }
            </TableRow>
            <TableRow data-testid={`period-collapse-${period.number}`}>
                <TableCell colSpan={4} className={classes.collapseRow}>
                    <Collapse in={open} timeout="auto">
                        <Box className={classes.expandedRow}>
                            <Typography variant="body2">
                                {period.detailedForecast}
                            </Typography>
                        </Box>

                    </Collapse>
                </TableCell>
            </TableRow>
        </>
    );
}
