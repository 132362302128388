import React, { useState, useEffect } from 'react';
import { BrowserRouter as Router } from 'react-router-dom';
import { CookiesProvider, useCookies } from 'react-cookie';

import { Grid, Link, CssBaseline, Typography, makeStyles, FormGroup, FormControlLabel, Switch }
    from '@material-ui/core';
import { createTheme, ThemeProvider } from '@material-ui/core/styles';

import Routes from './components/Routes'
import { UnitPreference } from './utils/DataDisplay';

const useStyles = makeStyles((theme) => ({
    unitSelect: {
        color: theme.palette.primary.contrastText,
    },
    selected: {
        color: theme.palette.primary.contrastText,
        fontWeight: 'bold'
    },
    darkModeFormLabel: {
        '& .MuiFormControlLabel-label': {
            color: theme.palette.primary.contrastText,
            ...theme.typography.body2
        }
    }
}));

function App() {
    const getCookieOptions = () => {
        const maxAge = 60 * 60 * 24 * 30 //30 days in seconds
        return {path: '/', maxAge: maxAge, secure: true}
    };
    const [cookies, setCookie] = useCookies(
        ['prefersDarkMode', 'unitPreference']
    );

    const [prefersDarkMode, setPrefersDarkMode] = useState(
        cookies.prefersDarkMode === undefined
            ? window.matchMedia("(prefers-color-scheme: dark)").matches
            : cookies.prefersDarkMode === 'true'
    );

    useEffect(() => {
        setCookie('prefersDarkMode', prefersDarkMode ? 'true' : 'false', getCookieOptions());
    }, [setCookie, prefersDarkMode]);

    const [unitPreference, setUnitPreferenceState] = useState(
        cookies.unitPreference === undefined ? UnitPreference.USA : parseInt(cookies.unitPreference, 10)
    );

    const setUnitPreference = (unitPreference) => {
        setCookie(
            'unitPreference', unitPreference, getCookieOptions()
        );
        setUnitPreferenceState(unitPreference);
    };

    const theme = createTheme({
        palette: {
            type: prefersDarkMode ? 'dark' : 'light',
            background: {
                paper: prefersDarkMode ? '#212121' : '#fff',
                default: prefersDarkMode ? '#121212' : '#eeeeee'
            },
            primary: {
                light: '#3A5674',
                main: '#293d52',
                contrastText: '#fff'
            },
            secondary: {
                main: prefersDarkMode ? '#2196f3' : '#E57700',
                contrastText: '#fff'
            }
        }
    });

    const classes = useStyles();

    const darkModeSwitch = (
        <Switch
            name="darkModeSlider" checked={prefersDarkMode} 
            onChange={() => setPrefersDarkMode(prefersDarkMode => !prefersDarkMode)}
        />
    );
    
    const darkModeFormGroup = (
        <FormGroup row>
            <FormControlLabel
                control={darkModeSwitch} label="Dark mode" className={classes.darkModeFormLabel}
            />
        </FormGroup>
    );

    const unitSelect = (
        <Grid container direction="row" justifyContent="center" alignItems="center" spacing={2}>
            <Grid item>
                <Typography variant="body2" className={classes.unitSelect}>
                    Units:
                </Typography>
            </Grid>
            {[
                {key: 'USA', name: 'USA'}, {key: 'CANADA', name: 'Canada'},
                {key: 'UK', name: 'UK'}, {key: 'INTERNATIONAL', name: 'International'}
            ].map((units) => (
                <Grid item key={units.key}>
                    <Typography variant="body2">
                        <Link
                            component="button"
                            onClick={() => setUnitPreference(UnitPreference[units.key])}
                            className={unitPreference === UnitPreference[units.key]
                                ? classes.selected : classes.unitSelect
                            }
                        >
                            {units.name}
                        </Link>
                    </Typography>
                </Grid>
            ))}
        </Grid>
    );

    return (
        <CookiesProvider>
            <ThemeProvider theme={theme}>
                <CssBaseline />
                <Router>
                    <Routes
                        prefersDarkMode={prefersDarkMode}
                        darkModeFormGroup={darkModeFormGroup}
                        unitPreference={unitPreference}
                        unitSelect={unitSelect}
                    />
                </Router>
            </ThemeProvider>
        </CookiesProvider>
    );
}

export default App;
