import React from 'react';

import { Grid, Typography, makeStyles } from '@material-ui/core';
import { Public, FilterHdr } from '@material-ui/icons';

import SnotelButton from '../common/SnotelButton';

const useStyles = makeStyles((theme) => ({
    menuText: {
        marginTop: theme.spacing(3)
    }
}));

export default function Menu(props) {
    const classes = useStyles();

    const buttons = [
        {displayText: "World Map", to: "/map", icon: <Public/>},
        {displayText: "State / Region", to: "/explore", icon: <FilterHdr/>}
    ]
    return (
        <Grid container spacing={1} data-testid="home-menu">
            <Grid item xs={12}>
                <Typography className={classes.menuText} variant="h6" component="h2">
                    Explore Mountains or Regions
                </Typography>
            </Grid>
            {buttons.map((button, i) => (
                <Grid item key={i.toString()} xs={12}>
                    <SnotelButton to={button.to} startIcon={button.icon}>
                        {button.displayText}
                    </SnotelButton>
                </Grid>
            ))}
        </Grid>
    )
}
