import React from 'react';

import { Grid } from '@material-ui/core';

import HistoricalSnowReport from './HistoricalSnowReport';
import StationSnowReport from './StationSnowReport';
import SkiAreaSnowReport from './SkiAreaSnowReport';

export default function SnowReport(props) {
    const { selectedLocation, unitPreference } = props;

    const snowReportTable = selectedLocation
        && (selectedLocation.locationTypeID === 1
            ? <StationSnowReport
                selectedLocation={selectedLocation} unitPreference={unitPreference}
            />
            : <SkiAreaSnowReport
                selectedLocation={selectedLocation} unitPreference={unitPreference}
            />
        );

    return (
        <Grid container direction="column" spacing={4}>
            <Grid item>
                {snowReportTable}
            </Grid>
            <Grid item>
                <HistoricalSnowReport />
            </Grid>
        </Grid>
    );
}
