export function sendServerRequest(requestType) {
    const restfulAPI = `${process.env.REACT_APP_SNOTEL_HOST}/${requestType}`;
    const requestOptions = {
        method: "GET"
    };
    return processRestfulAPI(restfulAPI, requestOptions);
}

export function sendServerRequestWithBody(requestBody) {
    const restfulAPI = `${process.env.REACT_APP_SNOTEL_HOST}/${requestBody.requestType}`;
    const requestOptions = {
        method: 'POST',
        body: JSON.stringify(requestBody)
    };

    return processRestfulAPI(restfulAPI, requestOptions);
}

async function processRestfulAPI(restfulAPI, requestOptions) {
    try {
        let response = await fetch(restfulAPI, requestOptions);
        return {
            statusCode: response.status,
            statusText: response.statusText,
            body: await response.json()
        };
    }
    catch (err) {
        console.error(err);
        return { statusCode: 0, statusText: 'Client failure', body: null };
    }
}
