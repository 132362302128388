function commasInNumber(number) {
    return number.toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,');
}

export const SourceUnit = Object.freeze({
    INCHES: 0,
    FEET: 1,
    METERS: 2,
    FAHRENHEIT: 3,
    MPH: 4
});

export const UnitPreference = Object.freeze({
    USA: 0,
    CANADA: 1,
    UK: 2,
    INTERNATIONAL: 3
});

/**
 * Converts a number to the user's preferred unit and returns a formatted string such as 10000ft or
 * 3,000m.
 * @param {number} number the source number.
 * @param {SourceUnit} sourceUnit the source number's unit of measurement.
 * @param {boolean} unitPreference true if the user prefers imperial units of measurement.
 * @param {boolean} addCommas true if the number should be formatted with commas.
 */
export function formatNumber(number, sourceUnit, unitPreference, addCommas) {
    let convertedNumber;
    let ending;

    switch (sourceUnit) {
        case SourceUnit.INCHES:
            if (unitPreference === UnitPreference.USA || unitPreference === UnitPreference.UK) {
                convertedNumber = number;
                ending = '"';
            } else {
                convertedNumber = Math.round(number * 2.54);
                ending = 'cm';
            }
            break;
        case SourceUnit.FEET:
            if (unitPreference === UnitPreference.USA || unitPreference === UnitPreference.UK) {
                convertedNumber = number;
                ending = 'ft';
            } else {
                convertedNumber = Math.round(number * 0.3048);
                ending = 'm';
            }
            break;
        case SourceUnit.METERS:
            if (unitPreference === UnitPreference.USA || unitPreference === UnitPreference.UK) {
                convertedNumber = Math.round(number * 3.28084);
                ending = 'ft';
            } else {
                convertedNumber = number;
                ending = 'm';
            }
            break;
        case SourceUnit.FAHRENHEIT:
            if (unitPreference === UnitPreference.USA) {
                convertedNumber = number;
                ending = '°F';
            } else {
                convertedNumber = Math.round((number - 32) * (5 / 9));
                ending = '°C';
            }
            break;
        case SourceUnit.MPH:
            if (unitPreference === UnitPreference.USA || unitPreference === UnitPreference.UK) {
                convertedNumber = number;
                ending = 'mph';
            } else if (unitPreference === UnitPreference.CANADA) {
                convertedNumber = Math.round(number * 1.60934);
                ending = 'km/h';
            } else {
                convertedNumber = Math.round(number * 0.44704);
                ending = 'm/s';
            }
            break;
        default:
            throw new Error(`Unknown SourceUnit ${sourceUnit}`);
    }

    if (addCommas) {
        return `${commasInNumber(convertedNumber)}${ending}`
    } else {
        return `${convertedNumber}${ending}`
    }
}

/**
 * Converts snow depth in inches to the user's preferred unit if needed and returns a formatted
 * string such as 30" or 50cm.
 * @param {number} snowDepth snow depth in inches.
 * @param {boolean} unitPreference true if the user prefers imperial units of measurement.
 */
export function formatSnowDepth(snowDepth, unitPreference) {
    return snowDepth !== undefined
        ? formatNumber(snowDepth, SourceUnit.INCHES, unitPreference, false) : '';
}
        