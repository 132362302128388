import React from 'react';
import { Switch, Route } from 'react-router-dom';

import { Grid, makeStyles } from '@material-ui/core';

import Navigation from './margins/Navigation';
import Home from './home/Home';
import WorldMap from './map/WorldMap';
import States from './explore/States';
import StateInfo from './explore/state/StateInfo';
import LocationPage from './location/LocationPage';
import Contact from './margins/Contact';
import About from './margins/About';
import Footer from './margins/Footer';
import ErrorPage from './margins/ErrorPage';
import Search from './margins/Search';

const useStyles = makeStyles((theme) => ({
    container: {
        minHeight: '100vh'
    }
}));

export default function Routes(props) {
    const classes = useStyles();

    return (
        <Grid container direction="column" justifyContent="space-between" className={classes.container}>
            <Grid item>
                <Navigation
                    prefersDarkMode={props.prefersDarkMode}
                />
                <Switch>
                    {process.env.REACT_APP_DOWN === 'true' && 
                        <Route path="*">
                            <ErrorPage
                                title="Down for maintenance | Snotel"
                                message="Snotel is currently down for maintenance. We expect to be back shortly."
                            />
                        </Route>
                    }
                    <Route exact path="/">
                        <Home
                            prefersDarkMode={props.prefersDarkMode}
                            unitPreference={props.unitPreference}
                        />
                    </Route>
                    <Route exact path="/search">
                        <Search/>
                    </Route>
                    <Route exact path="/contact">
                        <Contact prefersDarkMode={props.prefersDarkMode}/>
                    </Route>
                    <Route exact path="/about">
                        <About/>
                    </Route>
                    <Route exact path="/map/:state?">
                        <WorldMap prefersDarkMode={props.prefersDarkMode}/>
                    </Route>
                    <Route path="/location/:stationUrlName/:tab?">
                        <LocationPage
                            prefersDarkMode={props.prefersDarkMode}
                            unitPreference={props.unitPreference}
                            showSnackbar={props.showSnackbar}
                        />
                    </Route>
                    <Route exact path="/explore">
                        <States unitPreference={props.unitPreference}/>
                    </Route>
                    <Route path="/explore/:state">
                        <StateInfo unitPreference={props.unitPreference}/>
                    </Route>
                    <Route path="*">
                        <ErrorPage
                            title="Page Not Found | Snotel" message="This page could not be found."
                        />
                    </Route>
                </Switch>
            </Grid>
            <Grid item>
                <Footer
                    darkModeFormGroup={props.darkModeFormGroup}
                    prefersDarkMode={props.prefersDarkMode}
                    unitSelect={props.unitSelect}
                />
            </Grid>
        </Grid>
    );
}
