import React, { useState, useEffect } from 'react';
import { Link as RouterLink } from 'react-router-dom';

import { Grid, Card, CardContent, Typography, Link, makeStyles }
    from '@material-ui/core';

import { sendServerRequest } from '../../../api/restfulAPI';
import { formatNumber, formatSnowDepth, SourceUnit } from '../../../utils/DataDisplay';
import ListSkeleton from '../../common/ListSkeleton';

const useStyles = makeStyles((theme) => ({
    link: {
        fontWeight: 'bold'
    },
    mt2: {
        marginTop: theme.spacing(2)
    },
    mt4: {
        marginTop: theme.spacing(4)
    }
}));

export default function SkiAreas(props) {
    useEffect(() => {
        if(props.stateName.length > 0) {
            document.title = `Ski Areas | ${props.stateName} | Snotel`;
        }
        
    }, [props.stateName]);

    const [skiAreas, setSkiAreas] = useState();

    //get stations from stateID
    useEffect(() => {
        let isMounted = true;

        const orderBy = props.selectedSort 
            ? props.selectedSort === 'alphabetical' ? 'alphabetical' : props.selectedSort
            : null;

        sendServerRequest(`ski-areas?stateCode=${props.stateCode}&sort=${orderBy}`)
            .then((response => {
                if (response.statusCode >= 200 && response.statusCode <= 299) {
                    if(isMounted) {
                        setSkiAreas(response.body.skiAreas);
                    }
                } else {
                    console.error("Response code: ", response.statusCode, response.statusText);
                }
            })
        );
        return () => { isMounted = false };
    }, [props.stateCode, props.selectedSort]);

    const classes = useStyles();

    const snowDepth = (value) => {
        return value !== undefined ? formatSnowDepth(value, props.unitPreference) : 'Unknown'
    }

    const topElevation = (value) => {
        return value
            ? formatNumber(value, SourceUnit.FEET, props.unitPreference, true) : 'Unknown'
    }

    const skiAreaInfo = (skiArea) => (
        <Grid container spacing={1} className={classes.mt2}>
            {[skiArea.snowDepth, skiArea.topElevation].map((value, i) => (
                <Grid 
                    item xs={12} key={i === 0 ? 'snow depth' : 'elevation'}
                >
                    <Grid container>
                        <Grid item xs={6}>
                            <Typography variant="body2">
                                {`${i === 0 ? 'Snow Depth:' : 'Top Elevation:'}`}
                            </Typography>
                        </Grid>
                        <Grid item xs>
                            <Typography variant="body2">
                                {i === 0 ? snowDepth(value) : topElevation(value)}
                            </Typography>
                        </Grid>
                    </Grid>
                </Grid>
            ))}
        </Grid>
    );
    
    return (
        <Grid container spacing={1}>
            {skiAreas 
                ? skiAreas.length > 0 
                    ? skiAreas.map((skiArea) => (
                        <Grid item xs={12} sm={12} md={6} lg={4} key={skiArea.name}>
                            <Card>
                                <CardContent>
                                    <Link 
                                        color="secondary" underline="none"
                                        className={classes.link}
                                        component={RouterLink} to={`/location/${skiArea.urlName}`}
                                    >
                                        {skiArea.name}
                                    </Link>
                                    {skiAreaInfo(skiArea)}
                                </CardContent>
                            </Card>
                        </Grid>
                    ))
                    : null
                : <ListSkeleton count={12} height={128}/>
            }
        </Grid>
    );
}
