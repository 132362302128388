import React from 'react';
import { Link } from 'react-router-dom';

import { Container, Grid, makeStyles, Chip, Typography, Box } from '@material-ui/core';

import { Background } from 'react-imgix';

import Menu from './Menu';
import TopSnowPack from './TopSnowPack';


const useStyles = makeStyles((theme) => ({
    header: {
        color: theme.palette.common.black,
        fontWeight: 'bold',
        paddingTop: theme.spacing(8),
        marginBottom: theme.spacing(2)
    },
    backgroundImage: {
        width: 'fit-content',
        margin: 'auto',
        paddingLeft: theme.spacing(0.5),
        paddingRight: theme.spacing(0.5),
        paddingBottom: theme.spacing(60),
        [theme.breakpoints.up('sm')]: {
            paddingBottom: theme.spacing(80)
        }
    },
    suggestedLocations: {
        backgroundColor: theme.palette.background.paper,
        padding: theme.spacing(1, 1, 1, 1),
        borderRadius: theme.shape.borderRadius * 2
    }
}));

export default function Home(props) {
    React.useEffect(() => {
        document.title = 'Snotel';
    }, []);

    const classes = useStyles();

    const locations = [
        { name: 'Jackson Hole', urlName: 'JacksonHole' },
        { name: 'Snowbird', urlName: 'Snowbird' },
        { name: 'Arapahoe Basin', urlName: 'ArapahoeBasin'}
    ];

    const suggestedLocations = (
        <Box className={classes.backgroundImage}>
            <Grid
                container alignItems="center" spacing={1} className={classes.suggestedLocations}
                data-testid="suggested-locations"
            >
                <Grid item>
                    <Typography variant="body2">Sample Our Reports:</Typography>
                </Grid>
                <Grid item xs={12} sm>
                    <Grid container spacing={1} justifyContent="space-between">
                        {locations.map((location) => (
                            <Grid item key={location.name}>
                                <Chip
                                    label={location.name} color="secondary" clickable={true}
                                    component={Link} to={`/location/${location.urlName}`}
                                />
                            </Grid>
                        ))}
                    </Grid>
                </Grid>
            </Grid>
        </Box>

    );

    return (
        <>
            <Background
                src="https://snotel.imgix.net/background.jpg"
                imgixParams={{ q: 60, auto: 'format', fit: 'crop', crop: 'top', sepia: 5 }}
            >
                <Typography
                    variant="h3" component="h1" align="center" className={classes.header}
                >
                    Ski Reports &amp; Snowpack Data
                </Typography>
                {suggestedLocations}
            </Background>
            <Container maxWidth="lg">
                <Grid container spacing={2}>
                    <Grid item xs={12} sm={12} md={8}>
                        <Menu prefersDarkMode={props.prefersDarkMode} />
                    </Grid>
                    <Grid item xs={12} sm={12} md={4}>
                        <TopSnowPack
                            prefersDarkMode={props.prefersDarkMode}
                            unitPreference={props.unitPreference}
                        />
                    </Grid>
                </Grid>
            </Container>
        </>
    );
}
