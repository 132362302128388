import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';

import { Button, Grid, Typography, Chip, makeStyles } from '@material-ui/core';
import { Skeleton } from '@material-ui/lab';
import { Place } from '@material-ui/icons';

import { sendServerRequest } from '../../api/restfulAPI';
import { formatSnowDepth } from '../../utils/DataDisplay';
import ErrorMessage from '../common/ErrorMessage';

const useStyles = makeStyles((theme) => ({
    label: {
        textTransform: 'none',
        justifyContent: 'left'
    },
    endIcon: {
        marginLeft: 'auto'
    },
    menuText: {
        marginTop: theme.spacing(3)
    },
    chip: {
        backgroundColor: theme.palette.type === 'light' 
            ? theme.palette.grey[400] : theme.palette.grey[700]
    }
}));

export default function TopSnowPack(props) {
    const classes = useStyles();

    const [topLocations, setTopLocations] = useState([]);
    const [error, setError] = useState(false);

    useEffect(() => {
        const p = 60 * 60 * 1000; //Milliseconds in an hour
        const offset = 5 * 24 * 60 * 60 * 1000; //5 days
        const date = new Date()
        date.setTime(Math.round((date.getTime() - offset) / p ) * p)

        sendServerRequest(`locations?updatedAfter=${date.getTime()}&sort=snowDepth&limit=5`)
        .then((response => {
            if (response.statusCode === 200) {
                setTopLocations(response.body.locations);
                setError(false);
            } else {
                setError(true);
            }
        }));
    }, []);

    const locationList = () => {
        return topLocations.map((location, i) => (
            <Grid item key={i.toString()} xs={12}>
                <Button 
                    classes={{ label: classes.label }}
                    fullWidth={true} variant="outlined" startIcon={<Place/>}
                    component={Link} to={`/location/${location.urlName}`}
                >
                    {`${location.name}, ${location.stateCode}`}
                    <Chip 
                        className={classes.endIcon} size="small"
                        label={formatSnowDepth(location.snowDepth, props.unitPreference)}
                        classes={{ root: classes.chip }}
                    />
                </Button>
            </Grid>
        ));
    };

    const listSkeleton = (
        [...Array(5)].map((val, i) => (
            <Grid item xs={12} key={i.toString()}>
                <Skeleton variant="rect" height={36}/>
            </Grid>
        ))
    );

    const errorMessage = (
        <Grid item className={classes.errorMessage}>
            <ErrorMessage
                message="A server error has occurred. Please try again later."
            />
        </Grid>
    );

    const gridItems = () => {
        if (topLocations.length > 0 && !error) {
            return locationList();
        } else if (!error) {
            return listSkeleton;
        } else {
            return errorMessage;
        }
    };

    return(
        <Grid container spacing={error ? 2 : 1} data-testid="top-snowpack">
            <Grid item xs={12}>
                <Typography className={classes.menuText} variant="h6" component="h2">
                    Top 5 Deepest Snowpack
                </Typography>
            </Grid>
            {gridItems()}
        </Grid>
    )
}
