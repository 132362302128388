import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';

import { CartesianGrid, Legend, Line, LineChart, ResponsiveContainer, XAxis } from 'recharts';
import { Box, Divider, Grid, makeStyles, Paper, Typography, useMediaQuery } from '@material-ui/core';
import { useTheme } from '@material-ui/styles';

import { sendServerRequest } from '../../api/restfulAPI';
import { Skeleton } from '@material-ui/lab';
import ErrorMessage from '../common/ErrorMessage';

const useStyles = makeStyles((theme) => ({
    graphContainer: {
        padding: theme.spacing(2, 2, 2, 2)
    },
    divider: {
        margin: theme.spacing(2, 0, 2, 0)
    }
}));

export default function HistoricalSnowReport(props) {
    const [reportNotAvailable, setReportNotAvailable] = useState(false);
    const [error, setError] = useState(false);

    const [currentYearData, setCurrentYearData] = useState([]);
    const [averages, setAverages] = useState([]);
    const [lowest, setLowest] = useState([]);
    const [highest, setHighest] = useState([]);
    const [percentOfAverage, setPercentOfAverage] = useState('');

    let urlParams = useParams();

    useEffect(() => {
        sendServerRequest(`locations/${urlParams.stationUrlName}/snowpack-stats`)
            .then((response => {
                if (response.statusCode === 200) {
                    if (response.body.lowest.length > 0) {
                        setCurrentYearData(response.body.current);
                        setAverages(response.body.averages);
                        setLowest(response.body.lowest);
                        setHighest(response.body.highest);
                        setPercentOfAverage(response.body.percentOfAverage);
                    } else {
                        setReportNotAvailable(true);
                    }
                } else {
                    setError(true);
                }
            }));
    }, [urlParams.stationUrlName]);

    const theme = useTheme();
    const prefersDarkMode = theme.palette.type === 'dark';
    const contrastColor = theme.palette.text.primary;

    const xsDown = useMediaQuery(theme => theme.breakpoints.down('xs'));
    const smUp = useMediaQuery(theme => theme.breakpoints.up('sm'));

    const lineProps = { type: "monotone", dot: false, isAnimationActive: false };

    const graph = (
        <ResponsiveContainer width="100%" aspect={xsDown ? (8 / 7) : (16 / 9)}>
            <LineChart>
                <CartesianGrid
                    horizontal={false} strokeDasharray="5 5"
                    stroke={prefersDarkMode ? theme.palette.grey[700] : theme.palette.grey[400]}
                />
                <Line
                    name="Lowest" dataKey="swe" data={lowest}
                    {...lineProps} stroke={contrastColor} strokeWidth={2} strokeDasharray="4 4"
                />
                <Line
                    name="Highest" dataKey="swe" data={highest}
                    {...lineProps} stroke={contrastColor} strokeWidth={2} strokeDasharray="4 4"
                />
                <Line
                    name="Average" dataKey="swe" data={averages}
                    {...lineProps} stroke={contrastColor} strokeWidth={3}
                />
                <Line
                    name="Current Year" dataKey="swe" data={currentYearData}
                    {...lineProps} stroke={theme.palette.secondary.main} strokeWidth={5}
                />
                <XAxis
                    dataKey="month" allowDuplicatedCategory={false}
                    axisLine={false} tickLine={false} tick={{ fill: contrastColor }}
                />
                <Legend layout="horizontal" align="left" verticalAlign="top" iconType="plainline" />
            </LineChart>
        </ResponsiveContainer>
    );

    const classes = useStyles();

    const getGraphContainer = () => {
        return (
            <Box>
                <Typography component="h2" variant="h6">
                    Snowpack
                </Typography>
                <Paper className={classes.graphContainer}>
                    <Grid container alignItems="center">
                        <Grid item>
                            <Typography component="span" variant="h6">
                                {percentOfAverage}
                            </Typography>
                        </Grid>
                        <Grid item>
                            <Typography variant="caption">
                                &nbsp;of average for the season
                            </Typography>
                        </Grid>
                    </Grid>
                    <Typography variant="caption">
                        Last updated on {currentYearData[currentYearData.length - 1].date}
                    </Typography>
                    <Divider className={classes.divider} />
                    {graph}
                </Paper>
            </Box>
        );
    }

    if (currentYearData.length > 0 && !reportNotAvailable) {
        return getGraphContainer();
    } else if (!reportNotAvailable && !error) {
        return (
            <Skeleton variant="rect" width={smUp ? 800 : null} height={580} />
        );
    } else if (reportNotAvailable && !error) {
        return null;
    } else {
        return (
            <ErrorMessage
                message="An error occurred while retrieving the snowpack information. Please try again later."
            />
        );
    }
}
