import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';

import { Container, Grid, makeStyles } from '@material-ui/core';

import { sendServerRequest } from '../../api/restfulAPI';
import { VIEW_OPTION_KEYS, useQuery, ViewTabs } from '../margins/ViewTabs';
import LocationMap from '../common/LocationMap';
import StateSelect from './StateSelect';
import ListButton from './ListButton';
import ErrorPage from '../margins/ErrorPage';

const useStyles = makeStyles((theme) => ({
    mt1: {
        marginTop: theme.spacing(1)
    },
    mt1mt2mdUp: {
        marginTop: theme.spacing(1),
        [theme.breakpoints.up('md')]: {
            marginTop: theme.spacing(2)
        }
    },
    mt2mdUp: {
        [theme.breakpoints.up('md')]: {
            marginTop: theme.spacing(2)
        }
    }
}));

export const SELECTED_STATE_DEFAULT = {key: 'all', name: 'All'};
const BOUNDS_DEFAULT = [[70.37, -164.29], [32.92, -103.79]];

export default function WorldMap(props) {
    const [selectedView, setSelectedView] = useState(null);

    let query = useQuery();
    let tabQueryParam = query.get('tab')

    //set selected view based on url search parameter
    useEffect(() => {
        if(tabQueryParam) {
            setSelectedView(tabQueryParam);
        } else {
            setSelectedView(VIEW_OPTION_KEYS[0]);
        }
    }, [tabQueryParam]);

    const [stateCode, setStateCode] = useState('');
    const [stationCount, setStationCount] = useState(-1);
    const [skiAreaCount, setskiAreaCount] = useState(-1);
    const [bounds, setBounds] = useState(BOUNDS_DEFAULT);

    const setDefaultState = () => {
        setStateCode(SELECTED_STATE_DEFAULT.key);
        setBounds(BOUNDS_DEFAULT);
        document.title = 'World Map | Snotel';
    }

    let urlParams = useParams();
    const selectedState = urlParams.state ? urlParams.state : SELECTED_STATE_DEFAULT.key;

    const [stateNotFound, setStateNotFound] = useState(false);
    const [locations, setLocations] = useState([]);

    useEffect(() => {
        if(selectedView) {
            const locationTypeID = selectedView === VIEW_OPTION_KEYS[0] ? 2 : 1
            const selectedStateQueryString = selectedState === SELECTED_STATE_DEFAULT.key ? '' : `&stateCode=${selectedState}`
            sendServerRequest(`locations?locationTypeID=${locationTypeID}${selectedStateQueryString}`)
            .then(response => {
                if (response.statusCode === 200 && response.body.locations.length > 0) {
                    setStateNotFound(false);
                    if(selectedState !== SELECTED_STATE_DEFAULT.key) {
                        sendServerRequest(`states?stateCode=${selectedState}&expand=map`)
                            .then(response => {
                                if (response.statusCode >= 200 && response.statusCode <= 299) {
                                    setStateCode(response.body.states[0].stateCode);
                                    setStationCount(response.body.states[0].stationCount);
                                    setskiAreaCount(response.body.states[0].skiAreaCount);
                                    setBounds(response.body.states[0].bounds);

                                    document.title = `${response.body.states[0].stateName} Map | Snotel`;
                                }
                            }
                        );
                    } else {
                        sendServerRequest(`locations/count`)
                        .then(response => {
                            if (response.statusCode >= 200 && response.statusCode <= 299) {
                                setStationCount(response.body.stationCount);
                                setskiAreaCount(response.body.skiAreaCount);
                            }
                        });
                        setDefaultState();
                    }
                    setLocations(response.body.locations);
                } else {
                    setStateNotFound(true);
                }
            });
        }
    }, [selectedView, selectedState]);

    const classes = useStyles();

    const regionNotFoundMessage = (
        <ErrorPage
            title="Region Not Found | Snotel" message="This region could not be found."
        />
    )

    return(
        <Container maxWidth="lg">
            {stateNotFound ? regionNotFoundMessage
                : <Grid container spacing={2}>
                    <Grid item xs={12} sm={12} md={3}>
                        <Grid container spacing={1} >
                            <ViewTabs
                                variant="map" stateCode={stateCode} stationCount={stationCount}
                                skiAreaCount={skiAreaCount} selectedView={selectedView}
                            />
                            <Grid item xs={6} sm={6} md={12}>
                                <StateSelect stateCode={stateCode} className={classes.mt2mdUp}/>
                            </Grid>
                            <Grid item xs={6} sm={6} md={12}>
                                <ListButton state={stateCode} className={classes.mt2mdUp}/>
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid item xs={12} sm={12} md={9} className={classes.mt1}>
                        <LocationMap 
                            bounds={bounds} locations={locations}
                            maxClusterRadius={70} prefersDarkMode={props.prefersDarkMode}
                        />
                    </Grid>
                </Grid>
            }
        </Container>
    );
}
