import React, { useEffect } from 'react';

import { Container, Paper, Typography, makeStyles, Link } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
    container: {
        marginTop: theme.spacing(3)
    },
    paper: {
        padding: theme.spacing(2)
    },
    header: {
        marginTop: theme.spacing(1)
    }
}));

export default function Contact(props) {
    useEffect(() => {
        document.title = 'About | Snotel';
    }, []);

    const classes = useStyles();

    const nwccLink = (
        <Link
            color="secondary" href="https://www.wcc.nrcs.usda.gov/snow/"
            target="_blank" rel="noopener"
        >
            National Water and Climate Center (NWCC) 
        </Link>
    );

    const readmeLink = (
        <Link
            color="secondary" href="https://github.com/jackv545/Snotel-UI#readme"
            target="_blank" rel="noopener"
        >
            GitHub readme
        </Link>
    );

    return (
        <Container maxWidth="md" className={classes.container}>
            <Paper className={classes.paper}>
                <Typography variant="h4" component="h1">
                    About Snotel
                </Typography>
                <br/>
                <Typography>
                    Snotel is a web application that provides snowpack information for lift-served
                    ski areas and backcountry locations in the Western United States. Snowpack data
                    is automatically retrieved from the US {nwccLink} and displayed here for your
                    convenience.
                </Typography>
                <Typography variant="h5" component="h2" className={classes.header}>
                    For Skiers &amp; Snowboarders
                </Typography>
                <Typography>
                    This web application is still a work in progress, but I hope you find it useful
                    in the meantime. Please check back later for additional features.
                </Typography>
                <Typography variant="h5" component="h2" className={classes.header}>
                    For Developers
                </Typography>
                <Typography>
                    You can learn more about the technical details of this project at
                    the {readmeLink}.
                </Typography>
            </Paper>
        </Container>
    );
}
