import React, { useState, useEffect } from 'react';

import { sendServerRequest } from '../../api/restfulAPI';
import LocationMap from '../common/LocationMap';

export default function Nearby(props) {
    const { prefersDarkMode, selectedLocation } = props;

    const [bounds, setBounds] = useState([]);
    const [nearbyLocations, setNearbyLocations] = useState([]);

    const updateNearbyLocations = (lat, lng) => {
        sendServerRequest(`locations/near?lat=${lat}&lng=${lng}&distance=60000&limit=10`)
        .then((response => {
            if (response.statusCode >= 200 && response.statusCode <= 299) {
                setBounds(response.body.bounds);
                setNearbyLocations(response.body.locations);
            } else {
                console.error("Response code: ", response.statusCode, response.statusText);
            }
        }));
    };

    useEffect(() => {
        if (selectedLocation) {
            const { lat, lng } = selectedLocation.coordinates;

            updateNearbyLocations(lat, lng);
        }
    }, [selectedLocation]);

    return (
        <LocationMap
            bounds={bounds} locations={nearbyLocations}
            selectedLocationURLName={selectedLocation?.urlName} maxClusterRadius={0}
            prefersDarkMode={prefersDarkMode}
        />
    );
}
