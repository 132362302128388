import React, { useState, useEffect } from 'react';
import { Link as RouterLink, useHistory } from 'react-router-dom';

import { Container, Grid, Chip, Paper, Link, Typography, makeStyles } from '@material-ui/core';

import { sendServerRequest } from '../../api/restfulAPI';
import ListSkeleton from '../common/ListSkeleton';
import { useQuery } from './ViewTabs';

const useStyles = makeStyles((theme) => ({
    resultContainer: {
        marginTop: theme.spacing(4)
    },
    resultCard: {
        padding: theme.spacing(2)
    },
    link: {
        color: theme.palette.secondary.main
    },
    chip: {
        backgroundColor: theme.palette.type === 'light' 
            ? theme.palette.grey[400] : theme.palette.grey[700]
    },
    noResultsMessage: {
        marginTop: theme.spacing(2)
    }
}));

export default function Search(props) {
    const [states, setStates] = useState({});
    const [isLoading, setIsLoading] = useState(false)
    useEffect(() => {
        setIsLoading(true);
        sendServerRequest('/states')
        .then((response => {
            if (response.statusCode >= 200 && response.statusCode <= 299) {
                const stateNameObject = Object.assign(...response.body.states.map(
                    state => ({[state.stateCode] : state.stateName})
                ))
                setStates(stateNameObject);
            } else {
                console.error("Response code: ", response.statusCode, response.statusText);
                setIsLoading(false);
            }
        }));
    }, []);

    let query = useQuery();
    let searchUrlParameter = query.get('q')
    const [locations, setLocations] = useState([]);

    const history = useHistory();

    useEffect(() => {
        setIsLoading(true);
        document.title = `${searchUrlParameter} - Search | Snotel`

        sendServerRequest(`locations/search?q=${searchUrlParameter}&getSuggestions=false`)
        .then((response => {
            if (response.statusCode >= 200 && response.statusCode <= 299) {
                if (response.body.locations.length === 1) {
                    history.replace(`location/${response.body.locations[0].urlName}`)
                } else {
                    setLocations(response.body.locations);
                    setIsLoading(false);
                }
            } else {
                console.error("Response code: ", response.statusCode, response.statusText);
                setIsLoading(false);
            }
        }));
    }, [searchUrlParameter, history]);

    const classes = useStyles();

    const resultCard = (location) => {
        return (
            <Paper className={classes.resultCard}>
                <Grid container alignItems="center" spacing={2}>
                    <Grid item>
                        <Chip
                            label={location.locationTypeID === 2 ? "Ski Area" : "Station"}
                            size="small" className={classes.chip}
                        />
                    </Grid>
                    <Grid item>
                        <Link
                            to={`/location/${location.urlName}`}
                            component={RouterLink} className={classes.link}
                        >
                            {`${location.name} · ${states[location.stateCode]} · United States`}
                        </Link>
                    </Grid>
                </Grid>
            </Paper>
        );
    };

    const noResultsMessage = (
        <Grid item className={classes.noResultsMessage}>
            <Typography>
                There are no results for your search: <b>{searchUrlParameter}</b>
            </Typography>
        </Grid>
    );

    const resultListing = () => {
        if (!isLoading && locations.length > 0) {
            return (
                locations.map((location, i) => (
                    <Grid item xs={12} key={i.toString()}>
                        {resultCard(location)}
                    </Grid>
                ))
            );
        } else if (!isLoading && locations.length === 0) {
            return noResultsMessage;
        } else {
            return (
                <ListSkeleton variant="search" count={5} height={56}/>
            );
        }
    }

    return (
        <Container maxWidth="sm" className={classes.resultContainer}>
            <Grid container spacing={2}>
                {resultListing()}
            </Grid>
        </Container>
    );
}
