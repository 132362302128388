import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';

import { Container, Grid, Card, CardContent, CardActions, Typography, Button, makeStyles } 
    from '@material-ui/core';

import ErrorPage from '../margins/ErrorPage';
import ListSkeleton from '../common/ListSkeleton';

import { sendServerRequest } from '../../api/restfulAPI';
import { formatSnowDepth } from '../../utils/DataDisplay';


const useStyles = makeStyles((theme) => ({
    container: {
        marginTop: theme.spacing(3)
    },
    button: {
        textTransform: 'none'
    },
    media: {
        borderRadius: '3px'
    },
    info: {
        marginBottom: theme.spacing(2)
    }
}));

export default function States(props) {
    const [statesList, setStatesList] = useState([]);
    const [error, setError] = useState(false);

    useEffect(() => {
        document.title = 'Explore | Snotel';
        
        sendServerRequest('states?expand=statePage&sort=alphabetical')
        .then((response => {
            if (response.statusCode >= 200 && response.statusCode <= 299) {
                setError(false);
                setStatesList(response.body.states);
            } else {
                setError(true);
            }
        }));
    }, []);

    const classes = useStyles();

    const stationButton = (name, snowDepth, urlName) => {
        return(
            snowDepth > 0
            ? <Grid item>
                <Button 
                    className={classes.button} 
                    component={Link} to={`/location/${urlName}`}>
                    {name}
                </Button>
            </Grid>
            : null
        );
    };

    const cardHeader = (stateCode, stateName) => {
        return(
            <Grid container spacing={1} alignItems="stretch">
                <Grid item>
                    <img 
                        src={`https://snotel.imgix.net/state-flags/${stateCode.toLowerCase()}.png?auto=format`}
                        alt={stateCode} className={classes.media}
                    />
                </Grid>
                <Grid item>
                    <Typography  className={classes.info}>
                        {stateName}
                    </Typography>
                </Grid>
            </Grid>
        );
    }

    const gridItems = () => {
        if (statesList.length > 0) {
            return statesList.map((state) => (
                <Grid item xs={12} sm={6} md={4} lg={3} key={state.stateCode}>
                    <Card className={classes.card}>
                        <CardContent>
                            {cardHeader(state.stateCode, state.stateName)}
                            <Typography variant="caption">
                                Top Snowpack
                            </Typography>
                            <Grid container justifyContent="space-between" alignItems="center">
                                <Grid item>
                                    <Typography variant="h5" component="h2">
                                        {formatSnowDepth(state.snowDepth, props.unitPreference)}
                                    </Typography>
                                </Grid>
                                {stationButton(
                                    state.locationName, state.snowDepth,
                                    state.locationURLName
                                )}
                            </Grid>
                        </CardContent>
                        <CardActions>
                            <Button 
                                className={classes.button} color="secondary"
                                component={Link} to={`/explore/${state.stateCode}`}
                            >
                                {`Compare ${state.stationCount + state.skiAreaCount} Mountains`}
                            </Button>
                        </CardActions>
                    </Card>
                </Grid>
            ))
        } else {
            return (
                <ListSkeleton variant="states" count={13} height={180}/>
            );
        }
    };

    const base = () => {
        if (!error) {
            return (
                <Grid container spacing={1}>
                    {gridItems()}
                </Grid>
            );
        } else {
            return (
                <ErrorPage
                    message="A server error has occurred. Please try again later."
                />
            );
        }
    }

    return (
        <Container maxWidth="lg" className={classes.container}>
            {base()}
        </Container>
    );
}
