import React from 'react';

import { Container } from '@material-ui/core';

import ErrorMessage from '../common/ErrorMessage';

interface ErrorPageProps {
    title?: string;
    message: string;
}

export default function ErrorPage(props: ErrorPageProps) {
    const { title, message } = props;
    React.useEffect(() => {
        if (title) {
            document.title = title
        }
    }, [title]);

    return(
        <Container maxWidth="md">
            <ErrorMessage message={message} fullPage justifyContentCenter/>
        </Container>
    );
}
