import React, { useState, useEffect } from 'react';

import { Paper, Typography, makeStyles, Grid, TableContainer, Table, TableHead, TableRow,
    TableCell, TableBody, useMediaQuery } from '@material-ui/core';
import { Skeleton } from '@material-ui/lab';

import { sendServerRequest } from '../../api/restfulAPI';
import { formatNumber, SourceUnit, UnitPreference } from '../../utils/DataDisplay';
import { getLastUpdatedText } from '../../utils/DateTime';
import ErrorMessage from '../common/ErrorMessage';
import ForecastPeriodRow from './ForecastPeriodRow';

const useStyles = makeStyles((theme) => ({
    errorMessage: {
        paddingTop: theme.spacing(6)
    }
}));

export default function Forecast(props) {
    const { unitPreference } = props;

    const [detailedForecastEnabled, setDetailedForecastEnabled] = useState(true);
    
    useEffect(() => {
        setDetailedForecastEnabled(unitPreference === UnitPreference.USA);
    }, [unitPreference]);

    const [forecastNotAvailable, setForecastNotAvailable] = useState(false);
    const [forecast, setForecast] = useState({});

    useEffect(() => {
        if (!props.forecastIsNotSupported && props.zoneID) {
            sendServerRequest(`forecast-zones/${props.zoneID}/forecast`)
            .then((response => {
                if (response.statusCode >= 200 && response.statusCode <= 299) {
                    setForecastNotAvailable(false);
                    setForecast(response.body.forecast);
                } else if (response.statusCode === 404) {
                    setForecastNotAvailable(true);
                } else {
                    console.error("Response code: ", response.statusCode, response.statusText);
                }
            }));
        } else if (props.forecastIsNotSupported) {
            setForecastNotAvailable(true);
        }
    }, [props.forecastIsNotSupported, props.zoneID]);

    const classes = useStyles();

    const getTableCaption = () => {
        if (forecast.hasOwnProperty('periods') && !forecastNotAvailable) {
            const forecastElevation =
                formatNumber(forecast.elevation, SourceUnit.FEET, unitPreference, true)
            const lastUpdated = getLastUpdatedText(forecast.lastUpdated)

            return (
                <caption>
                    <Grid container justifyContent="center" spacing={2}>
                        <Grid item>
                            <Typography variant="caption">
                                Forecast Elevation: {forecastElevation}
                            </Typography>
                        </Grid>
                        {props.bottomElevation > 0 && <Grid item>
                            <Typography variant="caption">
                                Bottom Elevation: {formatNumber(
                                    props.bottomElevation, SourceUnit.FEET, unitPreference, true
                                )}
                            </Typography>
                        </Grid>}
                        {props.topElevation > 0 && <Grid item>
                            <Typography variant="caption">
                                Top Elevation: {formatNumber(
                                    props.topElevation, SourceUnit.FEET, unitPreference, true
                                )}
                            </Typography>
                        </Grid>}
                        {props.elevation > 0 && <Grid item>
                            <Typography variant="caption">
                                Station Elevation: {formatNumber(
                                    props.elevation, SourceUnit.FEET, unitPreference, true
                                )}
                            </Typography>
                        </Grid>}
                        <Grid item>
                            <Typography variant="caption">Last Updated: {lastUpdated}</Typography>
                        </Grid>
                    </Grid>
                </caption>
            );
        }
    };

    const smUp = useMediaQuery(theme => theme.breakpoints.up('sm'));

    if (forecastNotAvailable) {
        return (
            <ErrorMessage
                message="Weather forecasts are not supported at this location."
                classes={{ root: classes.errorMessage }}
            />
        );
    } else if (forecast.hasOwnProperty('periods')  && !forecastNotAvailable) {
        return (
            <TableContainer component={Paper}>
                <Table size="small" aria-label="daily-forecast-table">
                    {getTableCaption()}
                    <TableHead>
                        <TableRow>
                            <TableCell></TableCell>
                            <TableCell></TableCell>
                            <TableCell>High/Low</TableCell>
                            {smUp && <TableCell>Wind Speed</TableCell>}
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {forecast.periods.map((period) => (
                            <ForecastPeriodRow
                                unitPreference={unitPreference} period={period}
                                detailedForecastEnabled={detailedForecastEnabled} key={period.id}
                            />
                        ))}
                    </TableBody>
                </Table>
            </TableContainer>
        );
    } else {
        return (
            <Skeleton variant="rect" width={smUp ? 800 : null} height={600}/>
        );
    }
}
