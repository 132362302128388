import React, { ReactNode }  from 'react';
import { Link as RouterLink } from 'react-router-dom';

import { Button, Link, createStyles, makeStyles, Theme } from '@material-ui/core';
import { ChevronRight } from '@material-ui/icons';

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        label: {
            textTransform: 'none',
            justifyContent: 'left'
        },
        endIcon: {
            color: theme.palette.secondary.main,
            marginLeft: 'auto'
        }
    })
);

interface SnotelButtonProps {
    to?: string;
    externalLink?: boolean;
    href?: string;
    startIcon: ReactNode;
    children: ReactNode;
    title?: string;
}

export default function SnotelButton(props: SnotelButtonProps) {
    const { to, externalLink, href, startIcon, children, title } = props;

    const classes = useStyles(props);

    const linkProps = externalLink
        ? {component: Link, target: "_blank", rel: "noopener", href: href, underline: "none"}
        : {component: RouterLink, to: to}

    return (
        <Button
            {...linkProps}
            classes={{ label: classes.label, endIcon: classes.endIcon }} 
            title={title} variant="outlined" fullWidth={true}
            startIcon={startIcon} endIcon={<ChevronRight/>}
        >
            {children}
        </Button>
    );
}
