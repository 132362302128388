import React  from 'react';

import { Grid, makeStyles, Typography, Paper } from '@material-ui/core';
import { Computer, DirectionsCar } from '@material-ui/icons';

import { Skeleton } from '@material-ui/lab';
import SnotelButton from '../common/SnotelButton';

const useStyles = makeStyles((theme) => ({
    detailsPaneWrapper: {
        padding: theme.spacing(1, 2, 4, 2)
    }
}));

export default function Details(props) {
    const classes = useStyles();

    const { selectedLocation } = props;

    const getSkiAreaDetails = () => {
        const latLng = `${selectedLocation.coordinates.lat},${selectedLocation.coordinates.lng}`;
        const directionsLink = `https://www.google.com/maps/dir/?api=1&destination=${latLng}`;

        const buttons = [{
            displayText: "Driving Directions", href: directionsLink,
            icon: <DirectionsCar/>, title: "Driving Directions - opens in a new tab"
        }];

        if (selectedLocation.website) {
            buttons.unshift({
                displayText: "Website", href: selectedLocation.website,
                icon: <Computer/>, title: `${selectedLocation.name} Website - opens in a new tab`
            });
        }
        
        return buttons.map(button => (
            <Grid item key={button.displayText}>
                <SnotelButton
                    externalLink href={button.href}
                    startIcon={button.icon} title={button.title}
                >
                    {button.displayText}
                </SnotelButton>
            </Grid>
        ));
    };

    const getStationDetails = () => {
        const details = [
            `${selectedLocation.name} is a Snotel station located in ${selectedLocation.county} County, ${selectedLocation.stateCode}.`,
            `Coordinates: (${selectedLocation.coordinates.lat.toFixed(3)}, ${selectedLocation.coordinates.lng.toFixed(3)})`
        ]
        return details.map((detail, i) => (
            <Grid item key={`location-detail-${i}`}>
                <Typography variant="body2">
                    {detail}
                </Typography>
            </Grid>
        ));
    };
    
    const getDetailsPane = () => {
        return (
            <Paper className={classes.detailsPaneWrapper}>
                <Grid container direction="column" spacing={1}>
                    <Grid item>
                        <Typography variant="h6" component="h2">
                            Mountain Information
                        </Typography>
                    </Grid>
                    {selectedLocation.locationTypeID === 1
                        && getStationDetails()
                    }
                    {selectedLocation.locationTypeID === 2
                        && getSkiAreaDetails()
                    }
                </Grid>
            </Paper>
        );
    };

    if (selectedLocation) {
        return getDetailsPane();
    } else {
        return (
            <Skeleton variant="rect" height={500}/>
        );
    }
}
