/**
 * @param {*} date 
 * @returns A string such as '2 hrs ago' or '1 day ago'.
 */
 export function getLastUpdatedText(date) {
    if (date) {
        let updatedTime = Date.now() - new Date(date).getTime();
        let p = 60 * 60 * 1000; // milliseconds in an hour
        let timeAgo = Math.round(updatedTime / p);
    
        let unit = '';
        if (timeAgo < 2) {
            timeAgo = 1;
            unit = 'hr';
        } else if (timeAgo > 1 && timeAgo < 24) {
            unit = 'hrs';
        } else if (timeAgo > 23 && timeAgo < 48) {
            timeAgo = 1;
            unit = 'day';
        } else {
            timeAgo = Math.round(timeAgo / 24);
            unit = 'days';
        }
        return `${timeAgo} ${unit} ago`
    } else {
        return ''
    }
}
